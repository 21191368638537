import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

const Home = {
  setFullbanner: function () {
    const fullbannerSections = document.querySelectorAll('[data-fullbanner]');
    fullbannerSections.forEach((section) => {
      const carousel = section.querySelector('.swiper');
      const next = section.querySelector('.swiper-button-next');
      const prev = section.querySelector('.swiper-button-prev');

      const newFullbanner = new Swiper(carousel, {
        slidesPerView: 1,
        spaceBetween: 0,
        watchOverflow: true,
        autoplay: {
          delay: 5000,
        },
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
      });
      
      newFullbanner.autoplay.stop();
      const setAutoPlay = () => {
        window.removeEventListener('scroll', setAutoPlay);
        window.removeEventListener('mousemove', setAutoPlay);
        fullbanner.autoplay.start();
      }
      window.addEventListener('scroll', setAutoPlay);
      window.addEventListener('mousemove', setAutoPlay);

    });
  },
  setSliderHome: function () {
    var sliderSectionHome = new Swiper('#swiper-section', {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 15,
      watchOverflow: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  },
  setSliderProducts: function () {
    var sliderProducts = new Swiper('#swiper-section-products', {
      loop: false,
      slidesPerView: 4,
      spaceBetween: 15,
      watchOverflow: true,
      pagination: {
        el: '.swiper-pagination-section-products',
        type: 'bullets',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        },
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },
    });
  },
  init: function () {
    var _this = this;
    _this.setFullbanner();
    if ($(window).width() >= 992){
      _this.setSliderProducts();
    }
    window.addEventListener('load', _this.setSliderHome);

    $('.category-tabs .links a').on('click', function (e) {
      e.preventDefault();
      $('.category-tabs .links a').removeClass('active');
      $(this).addClass('active');
      var tag = $(this).attr('href');
      $('.category-tabs .item').removeClass('active');
      $('[data-link="' + tag + '"]').addClass('active');
    });
  },
};

window.addEventListener('DOMContentLoaded', () => {
  Home.init();
})
